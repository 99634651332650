
$theme-colors: map-merge( $theme-colors, $wize-colors );
//$text-variants: map-merge( $text-variants, $wize-text-variants);
$table-variants: map-merge( $table-variants, $wize-table-variants);
//the following are mandatory for bootstrap 5.1+
$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");

pre {
    background-color: $dark !important;
}
pre code,
code {
    color: $light !important;
    background-color: transparent !important;
}