
$wize-colors: ( 
    "tertiary": $tertiary, 
    "security": $security,
    "resume": $resume,
    "content": $content,
    "catalog": $catalog,
    "navigation": $navigation,
    "white": $white,
    "keyword": $primary,
    "class": $tertiary,
    "interface": $yellow-500,
);
$wize-table-variants: ( 
    "tertiary": shift-color($tertiary, $table-bg-scale), 
    "security": shift-color($security, $table-bg-scale), 
    "resume": shift-color($resume, $table-bg-scale), 
    "content": shift-color($content, $table-bg-scale), 
    "catalog": shift-color($catalog, $table-bg-scale), 
    "navigation": shift-color($navigation, $table-bg-scale), 
    "white": shift-color($white, $table-bg-scale), 
);

// Type variables per color group

$security-focus-color: rgba($security, $focus-color-opacity) !default;
$security-focus-box-shadow: 0 0 0 $focus-width $security-focus-color !default;
$resume-focus-color: rgba($resume, $focus-color-opacity) !default;
$resume-focus-box-shadow: 0 0 0 $focus-width $resume-focus-color !default;
$content-focus-color: rgba($content, $focus-color-opacity) !default;
$content-focus-box-shadow: 0 0 0 $focus-width $content-focus-color !default;
$catalog-focus-color: rgba($catalog, $focus-color-opacity) !default;
$catalog-focus-box-shadow: 0 0 0 $focus-width $catalog-focus-color !default;
$navigation-focus-color: rgba($navigation, $focus-color-opacity) !default;
$navigation-focus-box-shadow: 0 0 0 $focus-width $navigation-focus-color !default;