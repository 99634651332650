@mixin card-control-type($color, $shadow) {
    .form-check-input:checked {
        background-color: $color;
        border-color: $color;
    }

    .form-control:focus,
    .form-select:focus,
    .page-link:focus {
        border-color: $color;
        box-shadow: $shadow;
    }

    .pagination {
        .page-item {
            background-color: $color;
            border-color: $color;

            &.active {
                .page-link {
                    //@include gradient-bg($color);
                    border-color: $color;
                }
            }
        }

        .page-link {
            color: $color;
        }
    }
}

.card-security {
    @include card-control-type($security, $security-focus-box-shadow);
}
.card-resume {
    @include card-control-type($resume, $resume-focus-box-shadow);
}
.card-content {
    @include card-control-type($content, $content-focus-box-shadow);
}
.card-catalog {
    @include card-control-type($catalog, $catalog-focus-box-shadow);
}
.card-navigation {
    @include card-control-type($navigation, $navigation-focus-box-shadow);
}

.form-floating-addon {
    position: absolute;
    top: 1rem;
    right: 1rem;
}
