@import "../node_modules/bootstrap/scss/functions";
@import "./assets/scss/variables";
// Toggle global options
$enable-gradients: true;
$enable-shadows: true;
@import "../node_modules/bootstrap/scss/variables";

@import "./assets/scss/mixins";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "./assets/scss/theme";
@import "../node_modules/bootstrap/scss/root";

@import "./assets/scss/utilities";
@import "../node_modules/bootstrap/scss/utilities";
@import "./assets/scss/forms";

// Layout & components
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/accordion";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/offcanvas";
@import "../node_modules/bootstrap/scss/placeholders";
// Helpers
@import "../node_modules/bootstrap/scss/helpers";
// Utilities
@import "../node_modules/bootstrap/scss/utilities/api";

body {
  background-color: $gray-100;
}
.devicon-auth0:before {
  content: url("./auth0.svg");
}

.nav-link {
  font-weight: 500;
}
.resume hr {
  height: 6px;
  opacity: 1;
}
pre {
  background-color: $gray-100;
  padding: $spacer;
  code {
    color: $code-color;
  }
}
code {
  background-color: $gray-100;
}

.display-hover {
  .card-footer[data-hover-showable] {
    position:absolute;
    bottom: 0;
    width: 100%;
  }
  [data-hover-showable] {
    background-color: #{$secondary}#{98};
    display: none;
    transition: display 1s ease;
  }
  &:hover [data-hover-showable] {
    display: initial;
  }
}

.text-hover-secondary:hover {
    color: $secondary;
}

.mw-50 {
  max-width: 50%;
}


/* Define a class for highlighting the target word */
.highlight {
  color: red;
  font-weight: bold;
}

