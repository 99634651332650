$font-size-base: 1.1rem;

// Colors
$primary: #007bff ;
$secondary: #6f42c1;
$tertiary: #28a745;
$success: #379923;
$info: #2B90CC;
$warning: #FF9E8F;
$danger: #CC2B2F;
$security: #ff0000;
$resume: #0065ec;
$content: #7400d0;
$catalog: #00b199;
$navigation: #cea700;
$white: #ffffff;


$dropdown-border-radius: .25rem;
$border-radius: 1.5rem;
$btn-border-radius: 1.5rem;
$border-radius-sm: 1.5rem;
$border-radius-lg: 1.5rem;
//$badge-border-radius: 0.25rem;
$input-bg: #f8f9fa;
$input-border-color: #f4f4f4;
//$input-line-height: 2.0;
//$form-floating-input-padding-t: 2rem;
//$form-floating-label-transform: scale(.85) translateY(-1rem) translateX(.15rem);
//$form-floating-padding-y: 1.5rem;


$focus-color-opacity: .25 !default;
$focus-width: .25rem !default;